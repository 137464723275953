import React, { useEffect, useState } from 'react';
import Button from 'stories/Button';
import { ScheduleIcon } from 'stories/Icons';
import { Body } from 'stories/Typography';
import { useTheme } from 'styled-components';

import { MessageWrapper, MultiFactorResendWrapper } from './styles';

interface MultiFactorResendProps {
  resendRequest?: () => Promise<void>;
}

export const MultiFactorResend = ({
  resendRequest,
}: MultiFactorResendProps) => {
  const theme = useTheme();
  const [didResend, setDidResend] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    h: 0,
    m: 15,
    s: 0,
  });

  const secondsToTime = (secs: number) => {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = secs % 60;
    return { h: hours, m: minutes, s: seconds };
  };

  const totalSeconds = 15 * 60;
  const [seconds, setSeconds] = useState(totalSeconds);

  useEffect(() => {
    const timerId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) clearInterval(timerId);
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    setTimeLeft(secondsToTime(seconds));
  }, [seconds]);

  const handleResend = async () => {
    if (!resendRequest) return;
    try {
      await resendRequest();
      setDidResend(true);
      setSeconds(totalSeconds);
    } catch (error) {
      console.error('Failed to resend code', error);
    }
  };

  return (
    <MultiFactorResendWrapper>
      <MessageWrapper>
        <ScheduleIcon
          fillColor={theme.colors.grey[400]}
          width="16px"
          height="16px"
        />
        <Body size="small" color={theme.colors.grey[400]}>
          This code will expire in {timeLeft.m}
          {timeLeft.m > 1 ? ' minutes' : ' minute'}.
        </Body>
        <Button
          type="button"
          variant="tertiary"
          onClick={handleResend}
          spacing={{ pl: 0, pb: 0, pr: 0, pt: 0, ml: 0 }}
        >
          {!didResend ? 'Resend code' : 'Try again'}.
        </Button>
      </MessageWrapper>
    </MultiFactorResendWrapper>
  );
};

export default MultiFactorResend;
