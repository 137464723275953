import { useRouter } from 'next/router';
import Button from 'stories/Button';
import { EmailInput, PasswordInput } from 'stories/InputFields';
import { emailSchema, passwordSchema } from 'stories/InputFields/schema';

import { AuthPageFieldsProps } from '../types';
import { ForgotPasswordButtonWrapper } from './styles';

export const SignIn = ({ methods }: AuthPageFieldsProps) => {
  const router = useRouter();

  return (
    <>
      <EmailInput
        {...methods.register('email', emailSchema)}
        size="large"
        label="Email"
        placeholder="Enter your email address"
        state={methods.formState.errors.email ? 'error' : 'default'}
        errorMessage={methods.formState.errors.email?.message as string}
      />

      <PasswordInput
        {...methods.register('password', passwordSchema)}
        size="large"
        label="Password"
        placeholder="Enter your password"
      />

      <ForgotPasswordButtonWrapper>
        <Button
          type="button"
          width="100%"
          variant="tertiary"
          spacing={{ pt: 0, pb: 0, pr: 0, pl: 0 }}
          onClick={() => router.push('/auth/forgot-password')}
          size="large"
        >
          Forgot password?
        </Button>
      </ForgotPasswordButtonWrapper>
    </>
  );
};
